import './App.css';
import React, { useState } from 'react';
import DesignScreen from './screens/DesignScreen';
import StartScreen from './screens/StartScreen';
import Faq from './screens/Faq';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import { IntlProvider } from 'react-intl'
import messages from './translations';
import LanguageSwitcher from './components/LanguageSwitcher';
import PageTitle from './components/PageTitle';

const locales = Object.keys(messages);

const theme = createTheme({
  spacing: 5,
  shape: {
    borderRadius: 15,
  },
  typography: {
    button: {
      textTransform: "none"
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 16,
      fontWeight: 400,
    },
    body1: {
      fontSize: 14,
      fontWeight: 500,
    },
    h3: {
      fontSize: 37,
      fontWeight: 700,
    },
    h4: {
      fontSize: 30,
      fontWeight: 400,
    },
    h5: {
      fontSize: 22,
      fontWeight: 400,
    },
    h6: {
      fontSize: 12,
      fontWeight: 600,
    },
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(255,255,255,0.9)'
      }
    },
  }
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#000',
  },
}));

function App() {
  // const [codeValue, setCodeValue] = useState({ code: "HC1:TEST", name: "Vladislavs Simanovičs", validFrom: "2021-07-02" });
  const [codeValue, setCodeValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [locale, setLocale] = useState(localStorage.getItem('locale') || locales[0]);
  const classes = useStyles();

  return (
    <IntlProvider messages={messages[locale]} locale={locale} defaultLocale="en">
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/faq">
              <Faq />
            </Route>
            <Route path="/">
              {codeValue ? <DesignScreen codeResponse={codeValue}
                onStartGeneratePass={() => setIsLoading(true)}
                onEndGeneratePass={() => setIsLoading(false)} /> : <StartScreen
                onStartCodeUpload={() => setIsLoading(true)}
                onEndCodeUpload={(code) => {
                  setIsLoading(false);
                  if (code) {
                    setCodeValue(code);
                  }
                }}
              />}
            </Route>
          </Switch>
        </Router>
        {codeValue ? null : <LanguageSwitcher locale={locale} onClick={() => {
          const currentIndex = locales.indexOf(locale);
          const nextIndex = (currentIndex + 1) % locales.length;
          const newLocale = locales[nextIndex];
          setLocale(newLocale);
          localStorage.setItem('locale', newLocale);
        }} />}
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress size={25} thickness={4} color="inherit" />
        </Backdrop>
        <PageTitle />
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
