import './../App.css';
import React from 'react';
import {
    IconButton,
    makeStyles,
} from '@material-ui/core';
import { ReactComponent as LV } from './../icons/lv.svg';
import { ReactComponent as EN } from './../icons/en.svg';
import { ReactComponent as RU } from './../icons/ru.svg';

const useStyles = makeStyles((theme) => ({
    language: {
        position: 'absolute',
        right: 0,
        top: 0,
        padding: 15,
    },
}));

function LanguageSwitcher({ locale, onClick }) {
    const classes = useStyles();
    const iconMapping = {
        en: <EN />,
        lv: <LV />,
        ru: <RU />,
    }
    return (
        <IconButton className={classes.language} onClick={() => {
            onClick();
        }}>
            {iconMapping[locale]}
        </IconButton>
    );
}

export default LanguageSwitcher;
