const en = {
    // Start screen
    certificateInYourWallet: "Covid-19 Certificate in your Apple Wallet",
    addYourQRCode: "Add QR code",
    addPictureOrTakeAPhoto: "png or make a photo",
    somethingWentWrong: "Please try again.",
    whySomethingWentWrong: "Why?",
    weDoNotStoreYourData: "We don't save your data",
    otherQuestionsAndAnswers: "Other questions and answers",

    // Design screen
    covidCertificate: "Covid-19 sertifikāts",
    validFrom: "Derīgs no",
    validTo: "Derīgs līdz",
    certificateBackgroundColor: "Card's color",
    addYourBackground: "Use your own image",
    addToAppleWallet: "Add to Apple Wallet",

    // FAQ screen
    faq: "Frequently asked questions",

    // FAQ questions
    doYouStoreMyData: "Are you going to save my QR code or Digital Covid-19 Certificate?",
    canIAddToAndroid: "I have an Android. Can I use your service?",
    whoCreatedTheService: "Who is the developer of this service?",
    whereToFindMyCode: "Where can I find the QR code?",
    whyUseThisService: "Why should I use your service?",
    whatInfoIsPresentOnTheCard: "What kind of information will be displayed on the card?",
    isItLegal: "Is it legal to show the Digital Covid-19 Certificate this way?",
    canIUseItAbroad: "Can I use this the Apple Wallet card abroad?",
    canIAddMyTestCertificate: "I have a Covid-19 Test Certificate. Can I add it to the Apple Wallet card as well?",
    canIAddMyRecoveryCertificate: "I have a Covid-19 Recovery Certificate. Can I add it to the Apple Wallet card as well?",
    isAppleWalletCodeEnough: "Is it enough to just show the Apple Wallet card?",
    whyCreateACardInWallet: "Why should I create a card in Apple Wallet?",
    otherOfficialInformation: "Other official information",
    contactInformation: "Contact information",

    // FAQ answers
    noWeDoNotStoreData: "No, we don't save your data. The added card will only be saved on your iPhone in the Apple Wallet app.",
    toAddUseThirdParty: "To add the card to an Android, please use third party services.",
    serviceCreator: "https://twiceapps.com/",
    visitOfficialCertificateSite: "Go to the official site https://covid19sertifikats.lv/, choose the related certificate and download it as an image. The image will be added to the Files app in the Downloads folder. Use this path for creating the Apple Wallet card.",
    weOnlyShowMinimalInfo: "We show only important information. Design. When you create the card, you can either choose a color or set a favorite photo as the background. Сonvenience. The Apple Wallet app will show you the card on the locked screen automatically, when you are at an airport, cinema or shopping center, for example, Spice.",
    certificateDetails: "Name, surname, the Digital Covid-19 Certificate validity date (if it exists) and a QR code.",
    yesCodeIsEnough: "Yes, the inspection personnel only need the QR code. ",
    weRecommendABackup: "Yes, but it's recommended to print the Digital Covid-19 Certificate from the https://covid19sertifikats.lv/.",
    yes: "Yes.",
    noYouNeedId: "No, besides the Apple Wallet card, you have to show your personal ID.",
    itIsMoreConvenient: "It's convenient. When visiting restaurants, cinemas or other public events, you may be asked to show your Digital Covid-19 Certificate. In the Apple Wallet app, your certificate will always be with you.",
    officialLinks: "https://www.spkc.gov.lv/lv\nhttps://www.vmnvd.gov.lv/lv\nhttps://covid19sertifikats.lv/",
    contactEmail: "twiceapps@gmail.com",
  }

export default en;
