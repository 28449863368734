const questions = [
    {
        question: 'doYouStoreMyData',
        answer: 'noWeDoNotStoreData',
        icon: null,
    },
    {
        question: 'canIAddToAndroid',
        answer: 'toAddUseThirdParty',
        icon: null,
    },
    {
        question: 'whereToFindMyCode',
        answer: 'visitOfficialCertificateSite',
        icon: null,
    },
    {
        question: 'whyUseThisService',
        answer: 'weOnlyShowMinimalInfo',
        icon: null,
    },
    {
        question: 'whatInfoIsPresentOnTheCard',
        answer: 'certificateDetails',
        icon: null,
    },
    {
        question: 'isItLegal',
        answer: 'yesCodeIsEnough',
        icon: null,
    },
    {
        question: 'canIUseItAbroad',
        answer: 'weRecommendABackup',
        icon: null,
    },
    {
        question: 'canIAddMyTestCertificate',
        answer: 'yes',
        icon: null,
    },
    {
        question: 'canIAddMyRecoveryCertificate',
        answer: 'yes',
        icon: null,
    },
    {
        question: 'isAppleWalletCodeEnough',
        answer: 'noYouNeedId',
        icon: null,
    },
    {
        question: 'whyCreateACardInWallet',
        answer: 'itIsMoreConvenient',
        icon: null,
    },
    {
        question: 'otherOfficialInformation',
        answer: 'officialLinks',
        icon: null,
    },
    {
        question: 'whoCreatedTheService',
        answer: 'serviceCreator',
        icon: null,
    },
    {
        question: 'contactInformation',
        answer: 'contactEmail',
        icon: null,
    },
];

export default questions;
