import './../App.css';
import React from 'react';
import {
    IconButton,
    makeStyles,
} from '@material-ui/core';
import { ReactComponent as Back } from './../icons/back.svg';


const useStyles = makeStyles((theme) => ({
    back: {
        position: 'absolute',
        left: 0,
        top: 0,
        padding: 15,
    },
}));

function BackButton({ onClick }) {
    const classes = useStyles();
    return (
        <IconButton className={classes.back} onClick={() => {
            onClick();
        }}>
            <Back style={{padding: '5px 3px 5px 3px'}} />
        </IconButton>
    );
}

export default BackButton;
