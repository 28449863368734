import lv from "./lv";
import ru from "./ru";
import en from "./en";

const messages = {
    lv,
    ru,
    en,
}

export default messages;
