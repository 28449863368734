import React from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';

const PageTitle = ({ title }) => {
    const intl = useIntl();
    const defaultTitle = intl.formatMessage({ id: 'covidCertificate' });
    return (
        <Helmet>
            <title>{title ? title : defaultTitle}</title>
        </Helmet>
    );
};

export default PageTitle;