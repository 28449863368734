import './../App.css';
import React, { useRef, useState } from 'react';
import { Typography } from '@material-ui/core';
import { Link, Button, Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { useIntl } from 'react-intl'
import { ReactComponent as QRLogo } from './../icons/qr.svg';
import { ReactComponent as Lock } from './../icons/lock.svg';
import { ReactComponent as MainLogo } from './../icons/main.svg';
import Div100vh from 'react-div-100vh'
import Resizer from "react-image-file-resizer";

const StyledLink = styled(Link)({
    color: '#0034B9',
});

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1500,
      1500,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

async function uploadCode(file) {
    const formData = new FormData();
    const fileToUpload = await resizeFile(file);
    formData.append('code', fileToUpload);
    const codeResult = await fetch("/decode",
        {
            body: formData,
            method: "post"
        });
    const responseJson = codeResult.json();
    return responseJson;
}

// VS: Refactor to a global loading state
function StartScreen({ onStartCodeUpload, onEndCodeUpload }) {
    const formElement = useRef(null);
    const uploadInputRef = useRef(null);
    const [errorValue, setErrorValue] = useState('');
    const intl = useIntl();
    return (
        <Div100vh>
            <div className="App">
                <Box m={4} style={{ maxWidth: 450 }}>
                    <form ref={formElement} className="App-form" encType="multipart/form-data">
                        <MainLogo />
                        <Box mt={6} mb={10}>
                            <Typography variant="h3">
                                {intl.formatMessage({ id: 'certificateInYourWallet' })}
                            </Typography>
                        </Box>
                        <input ref={uploadInputRef} hidden type="file" id="code" name="code" accept="image/*" onChange={async (e) => {
                            setErrorValue(null);
                            onStartCodeUpload();
                            try {
                                // VS: Parse actual errors properly
                                const codeResponse = await uploadCode(e.target.files[0]);
                                if (codeResponse.statusCode && codeResponse.statusCode !== 200) {
                                    setErrorValue(codeResponse.message);
                                    onEndCodeUpload(null);
                                }
                                else {
                                    onEndCodeUpload(codeResponse);
                                }
                            }
                            catch (e) {
                                // VS: Parse actual errors properly
                                setErrorValue('Something went wrong');
                                onEndCodeUpload(null);
                            }
                        }} />
                        <Button fullWidth startIcon={<QRLogo />}
                            style={{ height: 56, color: '#0034B9', backgroundColor: 'rgba(0, 52, 185, 0.1)' }}
                            disableElevation variant="contained" onClick={
                                () => uploadInputRef.current && uploadInputRef.current.click()
                            }>
                            {intl.formatMessage({ id: 'addYourQRCode' })}
                        </Button>
                        <Box mt={2} />
                        <Typography style={{ color: '#838383' }} variant="body1">
                            {intl.formatMessage({ id: 'addPictureOrTakeAPhoto' })}
                        </Typography>
                        {errorValue ? <Box mt={4}>
                            <Alert icon={false} severity="error">
                                {intl.formatMessage({ id: 'somethingWentWrong' })}
                                <Link href="/faq" variant="body1">
                                    {' ' + intl.formatMessage({ id: 'whySomethingWentWrong' })}
                                </Link>
                            </Alert>
                        </Box> : null}
                        <Box mt={10} />
                        <Box display={'flex'} alignItems={'center'}>
                            <Lock />
                            <Box mr={2} />
                            <Typography variant="body1">
                                {intl.formatMessage({ id: 'weDoNotStoreYourData' })}
                            </Typography>
                        </Box>
                        <Box mt={3} />
                        <StyledLink href="/faq" variant="body1">
                            {intl.formatMessage({ id: 'otherQuestionsAndAnswers' })}
                        </StyledLink>

                    </form>
                </Box>
            </div>
        </Div100vh>
    );
}

export default StartScreen;
