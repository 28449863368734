import './../App.css';
import React, { useState, useRef } from 'react';
import { RgbColorPicker } from "react-colorful";
import { Popover } from '@material-ui/core';
import pickerIcon from './../icons/picker.svg';



function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return { r, g, b };
    } else {
        return { r, g, b };
    }
}

function ColorPicker({ color, onChange, colors }) {
    const selectedColor = '#000';
    const unselectedColor = 'transparent';

    const isCustomSelected = colors.filter((x) => {
        const colorRGB = hexToRGB(x);
        const isCurrent = colorRGB.r !== color.r || colorRGB.g !== color.g || colorRGB.b !== color.b;
        return isCurrent;
    }).length === colors.length;
    const customBorderColor = isCustomSelected ? selectedColor : unselectedColor;


    const [popover, setPopover] = useState(null);
    return <div style={{ display: 'flex', flexWrap: 'wrap', width: 276 }}>
        {colors.map((x, index) => {
            const currentRGB = hexToRGB(x);
            const isCurrent = currentRGB.r === color.r && currentRGB.g === color.g && currentRGB.b === color.b;
            const borderColor = isCurrent ? selectedColor : unselectedColor;
            return <div key={index} style={{ display: 'inline-block', border: `1px solid ${borderColor}`, margin: 3, 
            borderRadius: '50%', WebkitTapHighlightColor: 'rgba(0,0,0,0)' }} onClick={() => {
                onChange({ rgb: currentRGB });
            }}>
                {<div style={
                    { backgroundColor: x, width: 32, height: 32, margin: 3, borderRadius: '50%', cursor: 'pointer' }
                } />}
            </div>
        })}
        <div key={'custom'} style={{ display: 'inline-block', border: `1px solid ${customBorderColor}`, margin: 3,
         borderRadius: '50%', WebkitTapHighlightColor: 'rgba(0,0,0,0)'  }} onClick={(e) => {
            setPopover(e.currentTarget);
        }}>
            <div style={
                { backgroundImage: `url(${pickerIcon})`, width: 32, height: 32, margin: 3, borderRadius: '50%', cursor: 'pointer' }
            } />
        </div>
        <Popover
            open={!!popover}
            anchorEl={popover}
            onClose={() => {
                setPopover(null);
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <RgbColorPicker color={color} onChange={(x) => {
                onChange({ rgb: x });
            }} />
        </Popover>
    </div>;
}

export default ColorPicker;
