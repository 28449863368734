const ru = {
    // Start screen
    certificateInYourWallet: "Covid-19 сертификат в твоем Apple Wallet",
    addYourQRCode: "Добавить QR-код",
    addPictureOrTakeAPhoto: "png или сфотографируйте",
    somethingWentWrong: "Не получилось, попробуйте еще раз.",
    whySomethingWentWrong: "Почему?",
    weDoNotStoreYourData: "Мы не сохраняем ваши данные",
    otherQuestionsAndAnswers: "Другие вопросы и ответы",

    // Design screen
    covidCertificate: "Covid-19 sertifikāts",
    validFrom: "Derīgs no",
    validTo: "Derīgs līdz",
    certificateBackgroundColor: "Цвет карточки",
    addYourBackground: "Загрузить свою картинку",
    addToAppleWallet: "Добавить в Apple Wallet",

    // FAQ screen
    faq: "Часто задаваемые вопросы",

    // FAQ questions
    doYouStoreMyData: "Cохраните ли вы мой QR-код или цифровой сертификат Covid-19?",
    canIAddToAndroid: "А в Android тоже можно добавить?",
    whoCreatedTheService: "Кто создатель сервиса?",
    whereToFindMyCode: "Где я могу найти QR-код?",
    whyUseThisService: "Почему использовать ваш сервис?",
    whatInfoIsPresentOnTheCard: "Какая информация будет показываться в карточке?",
    isItLegal: "Это легально так показывать цифровой сертификат Covid-19?",
    canIUseItAbroad: "Можно ли использовать эту карточку заграницей?",
    canIAddMyTestCertificate: "Я сделал тест и у меня есть цифровой сертификат Covid-19, могу ли я его добавить?",
    canIAddMyRecoveryCertificate: "Я переболел и у меня есть цифровой сертификат Covid-19, могу ли я его добавить?",
    isAppleWalletCodeEnough: "Достаточно ли показать карточку в Apple Wallet?",
    whyCreateACardInWallet: "Зачем мне создавать карточку в Apple Wallet?",
    otherOfficialInformation: "Другая официальная информация",
    contactInformation: "Контактная информация",

    // FAQ answers
    noWeDoNotStoreData: "Нет, мы не сохраняем никакие данные. Добавленная карточка сохраняется только на вашем телефоне в приложении Apple Wallet.",
    toAddUseThirdParty: "Чтобы добавить карточку на Android, воспользуйтесь сторонними сервисами.",
    serviceCreator: "https://twiceapps.com/",
    visitOfficialCertificateSite: "Зайдите на официальный сайт https://covid19sertifikats.lv/, выберите нужный сертификат и скачайте изображение цифрового сертификата Covid-19. Картина будет добавлена в Files в папку Downloads. Используйте ее при создании карточки для Apple Wallet.",
    weOnlyShowMinimalInfo: "Мы показываем только необходимую информацию. Дизайн. При создании карточки вы можете выбрать цвет или поставить на фон любимую фотографию. Удобство. Apple Wallet автоматически покажет карточку на экране, когда вы будете в аэропорту, кинотеатре или торговом центре, например Spice.",
    certificateDetails: "Имя, фамилия, срок действия цифрового сертификата Covid-19 (если такой есть) и QR-код.",
    yesCodeIsEnough: "Да, проверяющим нужен только QR-код.",
    weRecommendABackup: "Да, но на всякий случай рекомендуем сделать распечатку сертификата Covid-19 c сайта https://covid19sertifikats.lv/.",
    yes: "Да.",
    noYouNeedId: "Нет, помимо карточки необходимо предъвить документ, подтверждающий личность.",
    itIsMoreConvenient: "Это удобно. Посещая рестораны, кинотеатры и другие мероприятия, вас могут попросить предъявить цифровой сертификат Covid-19. В Apple Wallet ваш сертификат всегда будет под рукой.",
    officialLinks: "https://www.spkc.gov.lv/lv\nhttps://www.vmnvd.gov.lv/lv\nhttps://covid19sertifikats.lv/",
    contactEmail: "twiceapps@gmail.com",
  }

export default ru;
