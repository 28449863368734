import './../App.css';
import React, { useState } from 'react';
import {
    Typography,
    makeStyles,
    Box,
    Collapse,
    ListItem
} from '@material-ui/core';
import { useIntl } from 'react-intl'
import { useHistory } from "react-router-dom";
import questions from '../data/faq';
import { ReactComponent as Down } from './../icons/down.svg';
import BackButton from '../components/BackButton';
import Div100vh from 'react-div-100vh';
import MuiLinkify from 'material-ui-linkify';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));


function Faq() {
    const intl = useIntl();
    const history = useHistory();
    const classes = useStyles();
    const [openItems, setOpenItems] = useState({});
    const toggleOpen = (index) => {
        const newItems = { ...openItems };
        newItems[index] = !newItems[index];
        setOpenItems(newItems);
    };
    document.body.style.backgroundColor = '#EFEEF3';
    return (
        <Div100vh>
            <div className="App-faq">
                <div style={{ width: '100%', maxWidth: 900, margin:'auto' }} >
                    <BackButton onClick={() => {
                        history.goBack();
                    }} />
                    <Box style={{ textAlign: 'start' }} m={4}>
                        <Box mt={12} />
                        <Typography variant="h3">
                            {intl.formatMessage({ id: 'faq' })}
                        </Typography>
                        <Box mt={6} />
                        {questions.map((x, index) => {
                            const isOpen = openItems[index];
                            return <div style={{
                                backgroundColor: 'white',
                                borderRadius: 10,
                            }}>
                                <ListItem disableRipple key={x.question} button onClick={() => {
                                    toggleOpen(index);
                                }}>
                                    <Box flexDirection={'row'} display={'flex'} alignItems={'center'} flex={'auto'}>
                                        <Box flexGrow={1} mr={8}>
                                            <Typography variant={'subtitle1'}>{intl.formatMessage({ id: x.question })}</Typography>
                                        </Box>
                                        <Down style={{ position: 'absolute', right: 20, top: 20, transform: `scaleY(${isOpen ? -1 : 1})` }} />
                                    </Box>
                                </ListItem>
                                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                                    <Box px={3} pb={3} pt={2}>
                                        <MuiLinkify LinkProps={{ style: { color: '#0034B9' } }}>
                                            <Typography variant={'body2'}>
                                                {intl.formatMessage({ id: x.answer })}
                                            </Typography>
                                        </MuiLinkify>
                                    </Box>
                                </Collapse>
                                <Box mb={3} />
                            </div>
                        })}
                    </Box>
                </div>
            </div>
        </Div100vh>
    );
}

export default Faq;
