const lv = {
    // Start screen
    certificateInYourWallet: "Covid-19 sertifikāts tavā Apple Wallet",
    addYourQRCode: "Pievienot QR kodu",
    addPictureOrTakeAPhoto: "png vai nofotografē",
    somethingWentWrong: "Lūdzu, mēģini vēlreiz.",
    whySomethingWentWrong: "Kāpēc?",
    weDoNotStoreYourData: "Mēs neglabāsim jūsu datus",
    otherQuestionsAndAnswers: "Citi jautājumi un atbildes",

    // Design screen
    covidCertificate: "Covid-19 sertifikāts",
    validFrom: "Derīgs no",
    validTo: "Derīgs līdz",
    certificateBackgroundColor: "Kartes krāsa",
    addYourBackground: "Izmantot savu attēlu",
    addToAppleWallet: "Pievienot Apple Wallet",

    // FAQ screen
    faq: "Biežāk uzdotie jautājumi",

    // FAQ questions
    doYouStoreMyData: "Vai mans QR kods / Covid-19 digitālais sertifikāts tiks saglabāts servisā?",
    canIAddToAndroid: "Vai sertifikātu var pievienot arī Android OS mobilos telefonos?",
    whoCreatedTheService: "Kas izstrādājis šo servisu?",
    whereToFindMyCode: "Kur es varu atrast savu QR kodu?",
    whyUseThisService: "Kāpēc izmantot šo servisu?",
    whatInfoIsPresentOnTheCard: "Kāda informācija tiks attēlota uz sertifikāta kartiņas?",
    isItLegal: "Vai šādi attēlots Covid-19 digitālais sertifikāts ir derīgs?",
    canIUseItAbroad: "Vai šo sertifikāta kartiņu varu izmantot ārzemēs?",
    canIAddMyTestCertificate: "Esmu veicis Covid-19 testu un man ir digitālais sertifikāts. Vai varu to pievienot?",
    canIAddMyRecoveryCertificate: "Esmu izslimojis Covid-19 un man ir digitālais sertifikāts. Vai varu to pievienot?",
    isAppleWalletCodeEnough: "Vai pārbaudes laikā ir pietiekoši parādīt sertifikāta kartiņu iekš Apple Wallet?",
    whyCreateACardInWallet: "Kāpēc man ir jāizveido sertifikāta kartiņa iekš Apple Wallet?",
    otherOfficialInformation: "Cita oficiāla informācija",
    contactInformation: "Kontaktinformācija",

    // FAQ answers
    noWeDoNotStoreData: "Nē, dati netiek saglabāti. Pievienotā sertifikāta kartiņa tiek saglabāta jūsu mobilā telefona aplikācijā Apple Wallet.",
    toAddUseThirdParty: "Lai pievienot sertifikāta kartiņu Android OS mobilā telefonā, nepieciešams izmantot atsevišķus servisus.",
    serviceCreator: "https://twiceapps.com/",
    visitOfficialCertificateSite: "Atveriet oficiālo vietni https://covid19sertifikats.lv/, izvēlieties nepieciešamo sertifikātu un lejupielādējiet Covid-19 digitālā sertifikāta attēlu. Attēls tiks pievienots Downloads mapītē iekš Files. Izmantojiet šo attēlu sertifikāta kartiņas izveidei iekš Apple Wallet.",
    weOnlyShowMinimalInfo: "Mēs attēlojam tikai nepieciešamo informāciju. Viegli lietojams dizains. Sertifikāta kartiņas izveides brīdī Jūs varat izvēlēties krāsu vai fonā izmantot jebkuru fotogrāfiju. Apple Wallet automātiski attēlos sertifikāta kartiņu uz ekrāna, atrodoties lidostā, kinoteatrī vai tirdzniecības centrā, piemēram, Spicē.",
    certificateDetails: "Vārds, uzvārds, derīguma termiņš Covid-19 digitālajam sertifikātam (ja tāds ir) un QR kods.",
    yesCodeIsEnough: "Jā, personai, kas pārbauda jūsu sertifikātu, ir nepieciešams tikai QR kods.",
    weRecommendABackup: "Jā, drošības apsvērumu dēļ, iesakām izdrukāt Covid-19 digitālā sertifikāta kopiju no vietnes https://covid19sertifikats.lv/.",
    yes: "Jā.",
    noYouNeedId: "Nē, papildus sertifikāta kartiņai ir nepieciešams parādīt arī personu apliecinošu dokumentu.",
    itIsMoreConvenient: "Tas ir ērti. Apmeklējot restorānus, kinoteātrus un citus pasākumus, jums var paprasīt parādīt covid-19 digitālo sertifikātu. Iekš Apple Wallet jūsu sertifikāts vienmēr būs pieejams.",
    officialLinks: "https://www.spkc.gov.lv/lv\nhttps://www.vmnvd.gov.lv/lv\nhttps://covid19sertifikats.lv/",
    contactEmail: "twiceapps@gmail.com",
}

export default lv;
