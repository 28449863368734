import './../App.css';
import { CirclePicker } from 'react-color';
import React, { useState, useRef } from 'react';
import { Button, Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl'
import { ReactComponent as Wallet } from './../icons/wallet-button.svg';
import { ReactComponent as Image } from './../icons/image.svg';
import { ReactComponent as LogoLV } from './../icons/logo-lv.svg';
import { ReactComponent as QRCard } from './../icons/qr-card.svg';
import QRCode from "react-qr-code";
import Div100vh from 'react-div-100vh';
import ColorPicker from '../components/ColorPicker';
import Resizer from "react-image-file-resizer";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

const ColorButton = withStyles((theme) => ({
    root: {
        color: '#fff',
        backgroundColor: '#000',
        '&:hover': {
            backgroundColor: '#000',
        },
        outlineColor: '#000',
    },
}))(Button);

function CardField({ fieldId, value }) {
    const intl = useIntl();
    return value ? <>
        <Typography style={{ textTransform: 'uppercase' }} variant="h6">{`${intl.formatMessage({ id: fieldId })}`}</Typography>
        <Box pt={1} />
        <Typography variant="h5">{value}</Typography>
    </> : null;
}

function DesignScreen({ codeResponse, onStartGeneratePass, onEndGeneratePass }) {
    const { code, name, validFrom, validTo } = codeResponse;
    const [color, setColor] = useState({ r: 59, g: 112, b: 92, a: 255 });
    const [image, setImage] = useState(null);
    const handleColorChange = (color) => {
        setColor(color.rgb);
    };

    const intl = useIntl();
    const submitInputRef = useRef(null);
    const backgroundInputRef = useRef(null);
    return (
        <>
            <svg style={{
                border: 0,
                clip: 'rect(0 0 0 0)',
                height: 1,
                margin: -1,
                overflow: 'hidden',
                padding: 0,
                position: 'absolute',
                width: 1,
            }}>
                <filter id='sharpBlur'>
                    <feGaussianBlur stdDeviation='30'></feGaussianBlur>
                    <feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 9 0'></feColorMatrix>
                    <feComposite in2='SourceGraphic' operator='in'></feComposite>
                </filter>
            </svg>
            <Div100vh>
                <div className="App">
                    <form style={
                        {
                            position: 'fixed',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: '#fff',
                        }
                    }
                        encType="multipart/form-data" method="POST" action="/generate">
                        <input type="hidden" name="code" value={code} />
                        <input type="hidden" name="color" value={JSON.stringify(color)} />
                        <div style={
                            {
                                // filter: 'blur(8px)',
                                // backgroundSize: 'cover',
                                // backgroundPosition: 'center',
                                // backgroundImage: `url(${image})`,
                                maxWidth: 420,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                position: 'fixed',
                                top: 20,
                                bottom: 0,
                                left: 30,
                                right: 30,
                                backgroundColor: `rgb(${color.r}, ${color.g}, ${color.b})`,
                                color: '#fff',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
                                borderRadius: 15,
                            }
                        }>
                            <div style={
                                {
                                    filter: 'url("#sharpBlur")',//'blur(8px)',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundImage: `url(${image})`,
                                    position: 'absolute',
                                    zIndex: -1,
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    borderRadius: 15,
                                }
                            } />
                            <Box px={4} pt={4}>
                                <Box display={'flex'}
                                    flexDirection={'row'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                    textAlign={'right'}>
                                    <LogoLV />
                                    <Typography variant="body1">
                                        {intl.formatMessage({ id: 'covidCertificate' })}
                                    </Typography>
                                </Box>
                                <Box textAlign={'left'} pt={10}>
                                    <Typography variant="h4">
                                        {name}
                                    </Typography>
                                    <Box pt={10} />
                                    <CardField fieldId={'validFrom'} value={validFrom} />
                                    <CardField fieldId={'validTo'} value={validTo} />
                                </Box>
                                <Box mt={20} />
                                <QRCard />
                                {/* <QRCode style={{ margin: 'atuo', backgroundColor: 'white', padding: 20 }} size={130} value={code} /> */}
                            </Box>
                        </div>
                        <div style={
                            {
                                backgroundColor: 'white',
                                position: 'fixed',
                                bottom: 0,
                                right: 0,
                                left: 0,
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                                boxShadow: '0px -1px 8px rgb(0 0 0 / 10%)',
                                paddingLeft: 20,
                                paddingRight: 20,
                                paddingTop: 30,
                                paddingBottom: 30,
                            }
                        }>
                            <Typography variant="body1" style={{ color: '#838383' }}>
                                {intl.formatMessage({ id: 'certificateBackgroundColor' })}
                            </Typography>
                            <Box mt={3} />
                            <ColorPicker color={color}
                                onChange={handleColorChange}
                                colors={["#f44336", "#f376dd", "#9c27b0", "#3f51b5", "#2196f3", "#00bcd4", "#3b705c", "#4caf50", "#8bc34a", "#ff9800", "#ff5722"]}
                            />
                            <input ref={backgroundInputRef} hidden type="file" id="background" name="background" accept="image/*" onChange={async (e) => {
                                if (e.target.files && e.target.files[0]) {
                                    const fileToUpload = await resizeFile(e.target.files[0]);
                                    e.target.files[0] = fileToUpload;
                                    let reader = new FileReader();
                                    reader.onload = function (ev) {
                                        setImage(ev.target.result);
                                    }.bind(this);
                                    reader.readAsDataURL(e.target.files[0]);
                                }
                            }} />
                            <Box mt={3} />
                            <Button fullWidth startIcon={<Image />}
                                style={{ color: '#0034B9', maxWidth: 335 }}
                                disableElevation variant="text" onClick={
                                    () => backgroundInputRef.current && backgroundInputRef.current.click()
                                }>
                                {intl.formatMessage({ id: 'addYourBackground' })}
                            </Button>
                            <Box mt={3} />
                            <input ref={submitInputRef} hidden type="submit" />
                            <ColorButton fullWidth startIcon={<Wallet />} style={{ height: 56, maxWidth: 335 }} onClick={
                                () => {
                                    submitInputRef.current && submitInputRef.current.click();
                                }
                            }>
                                {intl.formatMessage({ id: 'addToAppleWallet' })}
                            </ColorButton>
                        </div>
                    </form>
                </div>
            </Div100vh>
        </>
    );
}

export default DesignScreen;
